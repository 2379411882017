import cssVars from 'css-vars-ponyfill';

cssVars({
	variables: {
		'primary': '29, 29, 29',
		'primary-dark': '130, 98, 76',
		'accent': '29, 29, 29',
		'accent-plus': '255, 255, 255',
	},
});
